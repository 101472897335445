<template>
  <div>
    <h2 class="content-title d-flex align-items-center gap-4">
      Statistics
      <a :href="`/locations/${locationId}/archive`" class="btn btn-sm rounded">Archive</a>
    </h2>
    <rangeDate />
    <div class="insights" v-if="insightsExists">
      <div class="widget">
        <div class="d-flex justify-content-between mb-4">
          <strong class="caption">
            Location listing stats
            <p class="text-muted mb-0">
              The number of times the listing was viewed on Maps and Search
            </p>
          </strong>
          <chartSwitcher
            name="mainStats"
            value-left="listingStats"
            value-right="fullChart"
            v-model="mainChartsType">
            <template #left-icon>
              <i class="icon far fa-chart-line" />
            </template>
            <template #right-icon>
              <i class="icon far fa-analytics" />
            </template>
          </chartSwitcher>
        </div>
        <chartLines
          v-show="mainChartsType === 'listingStats'"
          :chart-ids="listingStatsChart.ids"
          :chart-colors="listingStatsChart.colors"
          chart-direction="in-row" />

        <MainChartWithTiles
          v-show="mainChartsType === 'fullChart'" />
      </div>
      <div class="insights-part">
        <div class="widget">
          <div class="d-flex justify-content-between">
            <strong class="caption">Business contacts</strong>
            <chartSwitcher
              name="businessContacts"
              v-model="businessChartType">
              <template #left-icon>
                <i class="icon far fa-chart-bar" />
              </template>
            </chartSwitcher>
          </div>
          <p class="text-muted">
            The number of times the phone or call now link was clicked / website link was clicked / driving directions
            were requested
          </p>
          <chartColumn
            v-show="businessChartType === 'other'"
            chart-type="column"
            :chart-height="300"
            :chart-ids="businessChart.ids"
            :chart-colors="businessChart.colors" />
          <chartLines
            v-show="businessChartType === 'line'"
            :chart-ids="businessChart.ids"
            :chart-colors="businessChart.colors" />
        </div>
      </div>
      <div class="insights-part">
        <div class="widget">
          <div class="d-flex justify-content-between mb-4">
            <strong class="caption">How customers search for a business</strong>
            <chartSwitcher
              name="impressionsPlatformType"
              value-left="mobile"
              value-right="desktop"
              v-model="impressionsPlatformType">
              <template #left-icon>
                <i class="icon far fa-mobile" />
              </template>
              <template #right-icon>
                <i class="icon far fa-desktop" />
              </template>
            </chartSwitcher>
            <chartSwitcher
              name="impressionsChartType"
              v-model="impressionsChartType">
              <template #left-icon>
                <i class="icon far fa-chart-pie" />
              </template>
            </chartSwitcher>
          </div>
          <chartPie
            v-show="impressionsChartType === 'other' && impressionsPlatformType === 'mobile'"
            chart-type="pie"
            :chart-height="300"
            :chart-ids="impressionsChartMobile.ids"
            :chart-colors="impressionsChartMobile.colors" />
          <chartLines
            v-show="impressionsChartType === 'line' && impressionsPlatformType === 'mobile'"
            :chart-ids="impressionsChartMobile.ids"
            :chart-colors="impressionsChartMobile.colors" />
          <chartPie
            v-show="impressionsChartType === 'other' && impressionsPlatformType === 'desktop'"
            chart-type="pie"
            :chart-height="300"
            :chart-ids="impressionsChartDesktop.ids"
            :chart-colors="impressionsChartDesktop.colors" />
          <chartLines
            v-show="impressionsChartType === 'line' && impressionsPlatformType === 'desktop'"
            :chart-ids="impressionsChartDesktop.ids"
            :chart-colors="impressionsChartDesktop.colors" />
        </div>
      </div>
    </div>
    <div class="insights" v-else>
      <div class="widget">
        <div class="empty-state text-center mt-3">
          <img src="~/no_data/insights.svg" alt="No Insights Img" height=100 width=150>
          <div class="text-center text-muted mb-2">
            <h3 class="mt-4 mb-4">You don't have any insights yet.</h3>
            Insights will be synced soon. Please wait.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import rangeDate from './range_date'
import chartLines from './chart_lines'
import chartColumn from './chart_column'
import chartPie from './chart_pie'
import chartSwitcher from './chart_switcher'
import MainChartWithTiles from './main_chart_with_tiles'

export default {
  components: {
    chartLines,
    chartColumn,
    chartPie,
    rangeDate,
    chartSwitcher,
    MainChartWithTiles
  },
  props: {
    locationId: { type: String, required: true }
  },
  computed: mapGetters(['insightsExists', 'chartData', 'compare']),
  data() {
    return {
      businessChartType: 'other',
      impressionsChartType: 'other',
      impressionsPlatformType: 'mobile',
      mainChartsType: 'listingStats'
    }
  },
  created() {
    this.listingStatsChart = {
      ids: ['business_impressions_search', 'business_impressions_maps'],
      colors: ['#f9ae34', '#377dff']
    }
    this.businessChart = {
      ids: ['call_clicks', 'website_clicks', 'business_direction_requests', 'business_conversations'],
      colors: ['#01c9a7', '#f9ae34', '#377dff', '#a3b2cb']
    }
    this.impressionsChartMobile = {
      ids: ['business_impressions_mobile_maps', 'business_impressions_mobile_search'],
      colors: ['#01c9a7', '#f9ae34']
    }
    this.impressionsChartDesktop = {
      ids: ['business_impressions_desktop_maps', 'business_impressions_desktop_search'],
      colors: ['#01c9a7', '#377dff']
    }
  }
}
</script>
