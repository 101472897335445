<template>
  <div class="mb-5">
    <h2 class="h3 m-0 mb-2 font-weight-semi-bold text-dark">
      Subscription
      <span class="badge badge-pill badge-info">{{ subscriptionPriceText }}</span>
    </h2>
    <form v-if="!freeSubscriptionLocal" class="narrow-form" @submit.prevent="onSubmit">
      <div class="d-flex gap-4 form-field pt-1">
        <button :disabled="isLoading" type="submit" class="btn btn-outline rounded">{{ submitText }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import { DEFAULT_ERROR_MESSAGE } from '../../../../common/constants'
import turbolinksReload from '../../../../common/turbolinks_reload'

export default {
  props: {
    mobileBusinessPath: { type: String, default: '' },
    subscriptionCancelled: { type: Boolean, default: false },
    freeSubscription: { type: Boolean, default: false },
    freeSubscriptionAvailable: { type: Boolean, default: false }
  },
  data() {
    return {
      isLoading: false,
      subscriptionCancelledLocal: this.subscriptionCancelled,
      freeSubscriptionLocal: this.freeSubscription,
      freeSubscriptionAvailableLocal: this.freeSubscriptionAvailable
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.isLoading = true
        if (this.subscriptionCancelledLocal) {
          await this.resubscribe()
        } else {
          await this.unsubscribe()
        }
        this.subscriptionCancelledLocal = !this.subscriptionCancelledLocal
      } catch {
        toastr.error(DEFAULT_ERROR_MESSAGE)
      } finally {
        this.isLoading = false
      }
    },
    resubscribe() {
      return axios.patch(`${this.mobileBusinessPath}/resubscribe`)
        .then((response) => {
          this.freeSubscriptionLocal = response.data.free_subscription
          turbolinksReload(() => {
            toastr.success('Subscribed successfully')
          })
        })
    },
    unsubscribe() {
      return axios.patch(`${this.mobileBusinessPath}/unsubscribe`)
        .then((response) => {
          this.freeSubscriptionLocal = false
          this.freeSubscriptionAvailableLocal = response.data.free_subscription_available
          turbolinksReload(() => {
            toastr.success('Unsubscribed successfully')
          })
        })
    }
  },
  computed: {
    submitText() {
      if (!this.subscriptionCancelledLocal) return 'Cancel subscription'
      return this.freeSubscriptionAvailableLocal ? 'Subscribe for free' : 'Subscribe for $99/mo'
    },
    subscriptionPriceText() {
      return this.freeSubscription ? 'Free' : '$99 / month'
    }
  }
}
</script>
