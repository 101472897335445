<template>
  <div class="scheduled-config-aside scroll ver">
    <Config v-if="showFullConfig"
      class="scheduled-config-aside__config"
      @hideConfigDetails="toggleFullConfig(false)" />
    <template v-else>
      <ConfigPreview :business-location-visible="businessLocationVisible"
        @showConfigDetails="toggleFullConfig(true)"
        @toggleShowBusinessLocation="toggleShowBusinessLocation" />
      <SearchTermsList class="scheduled-config-aside__search-terms scroll ver" />
    </template>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import ConfigPreview from './config_preview'
import Config from './config'
import SearchTermsList from './search_terms_list'

export default {
  components: {
    ConfigPreview,
    SearchTermsList,
    Config
  },
  data: () => ({
    showFullConfig: false,
    businessLocationVisible: false
  }),
  computed: {
    ...mapState(['config'])
  },
  methods: {
    ...mapMutations([
      'toggleBusinessMarker',
      'toggleGeogridMarkers',
      'repeatConfig'
    ]),
    toggleFullConfig(state) {
      this.showFullConfig = state
      this.toggleShowBusinessLocation(state)
      this.toggleGeogridMarkers(!state)
    },
    toggleShowBusinessLocation(show) {
      if (!this.config.attrs.businessLocationLat) return

      this.toggleBusinessMarker({ attrs: this.config.attrs, show })
      if (typeof show === 'boolean') {
        this.businessLocationVisible = show
      } else {
        this.businessLocationVisible = !this.businessLocationVisible
      }
    }
  },
  watch: {
    showFullConfig(newVal) {
      this.$emit('setShowFullConfig', newVal)
    }
  }
}
</script>
