<template>
  <div>
    <div v-if="adminPrivileges && !organizationLinda" class="d-inline-flex w-100 position-relative">
      <a :href="employeeNewUrl" class="btn btn-outline btn-primary">Add employee</a>
      <div v-if="employees.length === 0" class="mv-hint">
        Invite your first team member.
      </div>

      <CheckboxToggle v-else v-model="showBlockedEmployees" class="ml-auto flex-row-reverse gap-1">
        <i class="fa-solid fa-user-xmark" />
        <span class="pl-2">Show Blocked Employees</span>
      </CheckboxToggle>
    </div>

    <table class="table mv-businesses-table mt-3">
      <thead>
        <tr>
          <th colspan="2" class="h-auto p-2 border-transparent" />
          <th colspan="4" class="h-auto p-2 text-center">Moments</th>
          <th colspan="2" class="h-auto p-2 border-transparent" />
        </tr>
        <tr>
          <th
            v-for="column in columns"
            :key="column.key"
            :class="{ 'text-nowrap text-right': column.key === 'moderationEnabled' }">
            {{ column.title }}
            <span v-if="column.key === 'moderationEnabled'"
              class="text-primary"
              data-toggle="tooltip"
              :data-original-title="modetationHint">
              <i class="far fa-info-circle" />
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <EmployeeTableRow v-for="employee in unblockedEmployees"
          :organization-linda="organizationLinda"
          :key="employee.id"
          :row="employee" />
      </tbody>
    </table>
  </div>
</template>

<script>
import CheckboxToggle from 'vue_widgets/components/checkbox_toggle'
import EmployeeTableRow from './table_row'

export default {
  components: { EmployeeTableRow, CheckboxToggle },
  props: {
    employees: { type: Array, required: true },
    adminPrivileges: { type: Boolean, required: true },
    organizationLinda: { type: Boolean, required: true, default: false }
  },
  data: () => ({
    showBlockedEmployees: false
  }),
  created() {
    this.modetationHint = 'When enabled, all moments will need to be reviewed and approved ' +
                'from the web application. When disabled, all moments will publish immediately ' +
                'to GBP and the WordPress plugin.'
    this.columns = [
      { key: 'fullName', title: 'Name' },
      { key: 'email', title: 'Email' },
      { key: 'pendingCount', title: 'Pending' },
      { key: 'approvedCount', title: 'Approved' },
      { key: 'rejectedCount', title: 'Rejected' },
      { key: 'totalCount', title: 'Total' },
      { key: 'businesses', title: 'Businesses' },
      { key: 'description', title: 'Additional' },
      { key: 'moderationEnabled', title: 'Moderation' }
    ]
  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip()
  },
  computed: {
    employeeNewUrl() {
      return `${window.location.pathname}/new`
    },
    unblockedEmployees() {
      if (this.showBlockedEmployees) return this.employees

      return this.employees.filter((employee) => !employee.blockedAt)
    }
  }
}
</script>
