<template>
  <div class="mt-3">
    <div ref="chart" style="height: 300px" />
    <StatsTiles />
  </div>
</template>

<script>
import Highcharts from 'highcharts'
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import { differenceClass } from '../../../../common/helpers'
import { formatDate } from '../../../../common/datepicker'
import StatsTiles from './stat_tiles'
import { prefilData } from './store_insights'

function tooltipHeader(name) {
  return `<span class="tooltip-title">${name}</span>`
}

function tooltipPoint(data, diff) {
  return '<div class="tooltip-line">' +
    `<span class="point" style="color:${data.color}">\u25CF</span>` +
    `<span class="date">${moment(data.x).format('D MMM YYYY')}</span>` +
    `<span class="value">${data.y}</span>` +
    `<span class="difference ${differenceClass(diff)}">${Math.abs(diff)}</span></div>`
}

function collectMainCompareChartData(stat, allChartData, allChartCompareData, insightsDateRanges) {
  const first = allChartData.find((el) => el.id === stat)
  const second = allChartCompareData.find((el) => el.id === stat)
  const { ranges } = insightsDateRanges

  if (first.data.length > second.data.length) {
    second.data = prefilData(first, second, formatDate(ranges[1].start))
  } else {
    first.data = prefilData(second, first, formatDate(ranges[0].start))
  }

  return [first, second].map((el, i) => ({
    ...el,
    xAxis: i,
    id: `${el.id}_compare`,
    name: el.name,
    dates: `(${formatDate(ranges[i].start)} - ${formatDate(ranges[i].end)})`
  }))
}

export default {
  components: { StatsTiles },
  computed: {
    ...mapState(['currentStat', 'allChartData', 'allChartCompareData', 'insightsDateRanges']),
    ...mapGetters(['compare', 'chartData'])
  },
  watch: {
    chartData() {
      let series
      if (this.compare) series = collectMainCompareChartData(this.currentStat, this.allChartData, this.allChartCompareData, this.insightsDateRanges)
      if (!this.compare) series = this.allChartData

      const tooltip = {
        backgroundColor: '#535c65',
        borderRadius: 4,
        borderWidth: 0,
        padding: 0,
        style: {
          fontSize: '14px',
          color: '#fff'
        },
        useHTML: true,
        shadow: false
      }
      if (this.compare) {
        tooltip.formatter = function formatter() {
          const i = this.series.data.indexOf(this.point)
          const memo = []
          memo.push(tooltipHeader(this.series.name))
          this.series.chart.series.reduceRight((prev, srs) => {
            if (srs.data[i].y !== null) {
              const diff = prev.length !== 0 ? (srs.data[i].y - prev.y) : 0
              memo.push(tooltipPoint(srs.data[i], diff))
            }
            return srs.data[i]
          }, [])

          return memo.join('')
        }
      } else {
        tooltip.padding = 12
        tooltip.style.fontSize = '12px'
        tooltip.shared = true
        tooltip.crosshairs = true
        tooltip.xDateFormat = '%d %B %Y'
      }

      if (this.chart) this.chart.destroy()
      this.chart = Highcharts.chart(this.$refs.chart, {
        alignTicks: false,
        title: false,
        chart: { height: 300 },
        credits: { enabled: false },
        xAxis: [
          { type: 'datetime' },
          { type: 'datetime', opposite: true }
        ],
        time: { useUTC: true },
        series: series,
        tooltip: tooltip
      })
    }
  }
}
</script>
