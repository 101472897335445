<template>
  <div class="moments-dashboard">
    <DashboardFilter
      v-model="filters"
      class="w-100" />
    <div class="d-flex align-items-end justify-content-between pt-4">
      <CustomSelect
        class="w-20"
        v-model="daysCovered"
        label="Highlight gaps"
        :values="daysCoveredOptions" />

      <div>
        <span class="text-dark bold mr-3">{{ firstLastDateString }}</span>
        <button class="btn btn-link" @click="setInterval(1)" :disabled="waitingResponse">
          <i class="fa-solid fa-chevron-left" />
        </button>
        <button class="btn btn-link ml-1" @click="setInterval(-1)" :disabled="waitingResponse"><i class="fa-solid fa-chevron-right" /></button>
      </div>
    </div>
    <VueSlimTable
      ref="table"
      :source="getBusinesses"
      :columns="columns"
      :per-page="10"
      class="table table-fixed mv-businesses-table mv-businesses-table--dashboard">
      <template v-for="column in columns" #[`head:${column.key}`]>
        <span class="text-break-after-each text-dark align-bottom" :key="column.key">
          {{ column.title }}
        </span>
      </template>
      <template #row="{ row }">
        <tr class="text-dark">
          <td class="moments-dashboard__cell">
            <div class="d-flex align-items-center justify-content-between w-full gap-2">
              <template v-if="row.disconnected">
                <i :id="row.id" class="color-warning far fa-exclamation-circle" />
                <BTooltip :target="row.id" title="Location is disconnected" />
              </template>
              <a :href="getBusinessLink(row.business.id)">{{ row.business.name }}</a>
            </div>
          </td>
          <td class="align-top moments-dashboard__cell"
            :class="{ 'text-disabled': row.draft_moments === 0 }"
            @click="row.draft_moments > 0 ? openSidebar(null, row.business, true) : null">
            <span class="moments-dashboard__tag">
              {{ row.draft_moments }}
            </span>
          </td>
          <td class="align-top moments-dashboard__cell">
            <span class="moments-dashboard__tag">
              {{ row.days_without_published_checkins ?? 'N/A' }}
            </span>
          </td>
          <td
            v-for="({ key, date, value, notCovered }) in formattedCells(columns.slice(3), row)"
            class="align-top moments-dashboard__cell"
            :key="key"
            :class="{ 'text-primary cursor-pointer': value > 0, 'text-disabled': value === 0 }"
            @click="value > 0 ? openSidebar(date, row.business) : null">
            <span
              class="moments-dashboard__tag"
              :class="{
                'moments-dashboard__tag--danger': notCovered,
                'moments-dashboard__tag--empty': value === 0 && (currentDate >= todayDate),
                'moments-dashboard__tag--primary-hover': value > 0
              }">
              {{ value }}
            </span>
            <a v-if="value === 0 && (currentDate >= todayDate)" class="moments-dashboard__add btn btn-primary btn-xs rounded"
              :href="newMomentLink(row.business, date)"
              target="_blank">
              <i class="far fa-circle-plus fa-fw" />
            </a>
          </td>
        </tr>
      </template>
      <template #pagination>
        <Pagination class="pb-0"
          :page="currentPage"
          :per-page="perPage"
          :total-count="totalCount"
          @updatePage="updatePageNumber" />
      </template>
    </VueSlimTable>
    <DashboardSidebar
      v-if="sidebar.active"
      :active="sidebar.active"
      :params="sidebar.params"
      :business-name="sidebar.businessName"
      :date="sidebar.params.scheduledAt"
      :new-moment-link="sidebar.newMomentLink"
      @close="closeSidebar" />
  </div>
</template>

<script>
import snakecaseKeys from 'snakecase-keys'
import Pagination from 'vue_widgets/components/pagination'
import CustomSelect from 'vue_widgets/components/custom_select'
import DashboardFilter from './filter'
import DashboardSidebar from './sidebar'

const TIMELINE_DAYS = 8
const DEFAULT_DAYS_COVERED = 7
const DAYS_COVERED_OPTIONS = Array(11).fill(0).map((_, index) => ({
  value: index,
  text: `${index} ${(index === 1) ? 'day' : 'days'} without posting`
}))

export default {
  components: {
    Pagination,
    DashboardFilter,
    DashboardSidebar,
    CustomSelect
  },
  data() {
    return {
      daysCovered: DEFAULT_DAYS_COVERED,
      daysCoveredOptions: DAYS_COVERED_OPTIONS,
      currentPage: 1,
      totalCount: 0,
      perPage: 20,
      todayDate: new Date(),
      currentDate: new Date(),
      sidebar: {
        active: false,
        params: {},
        businessName: '',
        newMomentLink: null
      },
      filters: {
        search: ''
      },
      firstLastDateString: '',
      columns: [],
      waitingResponse: true,
      timeout: null
    }
  },
  methods: {
    setInterval(direction) {
      this.waitingResponse = true
      this.currentDate.setDate(this.currentDate.getDate() - direction * TIMELINE_DAYS)

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$refs.table.reload()
      }, 500)
    },
    newMomentLink(business, date) {
      const params = {
        businessId: business.id
      }

      if (date) {
        params.date = date
      }

      return `/gb/moments/new?${qs.stringify(snakecaseKeys(params))}`
    },
    getBusinessLink(id) {
      return `businesses/${id}`
    },
    getBusinesses(prms = {}) {
      return axios.get(
        '/gb/ajax/businesses/dashboard',
        {
          params: {
            order: prms.order,
            ...this.backendFilters,
            startDate: this.currentDate.toDateString(),
            perPage: this.perPage,
            page: this.currentPage
          },
          paramsSerializer(json) { return qs.stringify(snakecaseKeys(json, { deep: true }), { arrayFormat: 'brackets' }) }
        }
      ).then((res) => {
        this.columns = res.data.columns
        this.firstLastDateString = `${this.columns.slice(3)[0].title} - ${this.columns.slice(3).slice(-1)[0].title}`
        this.totalCount = parseInt(res.headers['total-count'], 10)
        return res.data.data
      }).finally(() => {
        this.waitingResponse = false
      })
    },
    formattedCells(dateColumns, row) {
      const cells = []

      let noPostingDays = row.days_without_published_checkins ?? 0

      dateColumns.forEach(({ key }) => {
        const cell = {
          date: key,
          key: `${row.business.id}${key}`,
          value: row[key]
        }

        if (cell.value === 0) {
          noPostingDays++
        } else {
          noPostingDays = 0
        }

        if (noPostingDays > this.daysCovered) {
          cell.notCovered = true
        }

        cells.push(cell)
      })

      return cells
    },
    openSidebar(date, business, showDrafts = false) {
      const params = {
        business: [business?.id]
      }

      if (!showDrafts) {
        params.scheduledAt = date
      } else {
        params.statuses = ['pending']
      }

      this.sidebar.active = true
      this.sidebar.params = params
      this.sidebar.businessName = business.name
      this.sidebar.newMomentLink = this.newMomentLink(business, date)
    },
    closeSidebar() {
      this.sidebar.active = false
      this.sidebar.params = {}
      this.sidebar.businessName = ''
    },
    updatePageNumber(page) {
      this.currentPage = page
      this.$refs.table.reload()
    }
  },
  computed: {
    backendFilters() {
      // eslint-disable-next-line no-unused-vars
      const { ...filters } = this.filters
      return filters
    }
  },
  created() {
    const savedDaysCovered = localStorage.getItem('savedDaysCovered')
    if (savedDaysCovered) {
      this.daysCovered = parseInt(savedDaysCovered, 10)
    }
  },
  watch: {
    backendFilters: {
      handler(old, newv) {
        if (JSON.stringify(old) === JSON.stringify(newv)) return
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.$refs.table.reload()
        }, 500)
      },
      deep: true
    },
    daysCovered() {
      localStorage.setItem('savedDaysCovered', this.daysCovered)
    }
  }
}
</script>
