<template>
  <div class="audit-report-head">
    <a v-if="!isPublic" class="btn btn-link gap-2 mb-3" href="/audit_reports">
      <i class="far fa-long-arrow-left font-size-20" />
      <span class="font-weight-normal">Back to Audits</span>
    </a>

    <div class="d-flex justify-content-between align-items-center mb-4">
      <div>
        <h1 class="h2 text-dark m-0">{{ report.configurationName }}</h1>
        <span class="text-muted">created {{ report.finishedAt }}</span>
      </div>
      <div v-if="!isPublic" class="d-flex align-items-center gap-2">
        <!-- <label class="switcher reverse gap-3 mr-4">
          <input type="checkbox">
          <span class="caption text-primary">Recomendadtions</span>
          <i class="toogle-switch" />
        </label>
        <a href="#" class="btn rounded gap-2">
          <i class="far fa-download" />
          PDF
        </a> -->

        <a :href="auditReportUrl" target="_blank" class="btn btn-transparent rounded gap-2">
          <i class="far fa-link" />
          Get Link
        </a>
        <a :href="`/audit_report_configurations/${report.configurationId}/edit`" class="btn btn-transparent rounded gap-2">
          <i class="far fa-edit" />
          Edit Configuration
        </a>
      </div>
    </div>

    <PrintHint class="noprint" />

    <div class="d-flex align-items-start flex-wrap flex-md-nowrap row-gap-2 col-gap-8 pb-2">
      <!-- <div class="audit-report-score positive">
        <span>Score</span>
        <strong>-</strong>
      </div> -->

      <dl class="w-100 m-0 pt-2">
        <dt class="font-weight-semi-bold">Location</dt>
        <dd class="font-weight-semi-bold mb-0">{{ location.name }}</dd>
      </dl>

      <dl class="w-100 m-0 pt-2">
        <dt class="font-weight-semi-bold">Keyword</dt>
        <dd class="font-weight-semi-bold mb-0">{{ report.keyword }}</dd>
      </dl>

      <dl class="w-100 m-0 pt-2">
        <dt class="font-weight-semi-bold">Place ID</dt>
        <dd class="font-weight-semi-bold mb-0 text-break">{{ location.placeId }}</dd>
      </dl>

      <dl class="w-100 m-0 pt-2">
        <dt class="font-weight-semi-bold">Address</dt>
        <dd class="font-weight-semi-bold mb-0">{{ location.address }}</dd>
      </dl>

      <dl class="w-100 m-0 pt-2">
        <dt class="font-weight-semi-bold">Store Code</dt>
        <dd class="font-weight-semi-bold mb-0">{{ location.storeCode || '—' }}</dd>
      </dl>

      <dl class="w-100 m-0 pt-2">
        <dt class="font-weight-semi-bold">Schedule</dt>
        <dd class="font-weight-semi-bold mb-0">{{ periodicity }}</dd>
        <dd class="font-weight-semi-bold mb-0">{{ report.scheduledAt }}</dd>
      </dl>
    </div>

    <div v-if="report.reportWarnings.length" class="d-flex align-items-center gap-2 text-warning mt-3 mb-4 font-weight-semi-bold">
      <i class="far fa-exclamation-circle" />
      {{ report.reportWarnings.join('. ') }}
    </div>
  </div>
</template>

<script>
import { periodicityOptions } from '../audit_report_configurations/helpers'
import PrintHint from '../components/print_hint'

const getNameByValue = (elements, val) => elements.find((elem) => elem.value === val)?.name

export default {
  components: {
    PrintHint
  },
  props: {
    report: { type: Object, required: true },
    isPublic: { type: Boolean, required: true },
    auditReportUrl: { type: String, required: true }
  },
  computed: {
    periodicity() {
      return getNameByValue(periodicityOptions, this.report.schedule.periodicity)
    },
    location() {
      return this.report.data.sections.location
    }
  },
  methods: {
    print() {
      window.print()
    }
  }
}
</script>
