<template>
  <div class="mv-checkin-item">
    <div class="mv-checkin-item-title">
      <img :src="require(`mv/moment_state_icons/${stateIcon()}`)">
      {{ checkin.fromOffice ? 'Created from the office' : checkin.formattedAddress }}
    </div>

    <div class="d-flex font-size-14 mb-3">
      <template v-if="masterDate">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="masterDate" />
      </template>
      <span v-else :class="['info-value font-weight-semi-bold text-capitalize', stateClass(checkin.aasmState)]">
        {{ checkin.aasmState === 'pending' ? 'Draft' : checkin.aasmState }}
      </span>
    </div>

    <div class="mv-checkin-item-wrapper">
      <div class="mv-checkin-item-info">
        <p class="text-break">{{ checkin.description }}</p>
        <div class="d-flex mb-2">
          <span class="info-key">Business</span>
          <span class="d-flex align-items-center gap-2 info-value">
            <template v-if="checkin.business.disconnected">
              <i :id="`${checkin.business.id}-${_uid}`" class="color-warning far fa-exclamation-circle" />
              <BTooltip :target="`${checkin.business.id}-${_uid}`" title="Location is disconnected" />
            </template>
            {{ checkin.business.name }}
          </span>
        </div>
        <div class="d-flex mb-2">
          <span class="info-key">Category</span>
          <span class="info-value">{{ checkin.category ? checkin.category.title : '-' }}</span>
        </div>
        <div class="d-flex mb-2">
          <span class="info-key">Customer</span>
          <span class="info-value">{{ checkin.customer }}</span>
        </div>
        <div v-if="checkin.employee" class="d-flex mb-2">
          <span class="info-key">Employee</span>
          <span class="info-value">{{ checkin.employee.name }}</span>
        </div>
        <div class="d-flex mb-2">
          <span class="info-key">Created</span>
          <span class="info-value font-weight-semi-bold text-capitalize">{{ checkin.createdAt }}</span>
        </div>
        <div v-if="checkin.approvedAt" class="d-flex mb-2">
          <span class="info-key">Approval date</span>
          <span class="info-value font-weight-semi-bold text-capitalize">
            {{ checkin.approvedAt }}
          </span>
        </div>
        <div v-if="checkin.scheduledAt !== null" class="d-flex mb-2">
          <span class="info-key">Scheduled for</span>
          <span class="info-value font-weight-semi-bold text-capitalize">
            {{ checkin.scheduledAt }}
          </span>
        </div>
        <div class="pt-2 " />
        <div v-if='checkin.publishVideo && checkin.videoData && checkin.videoData.length' class="d-flex mb-2">
          <span class="info-key">Video status</span>
          <div v-if='gbpPostStatus !== "skipped"' class="d-flex align-items-center flex-wrap gap-1 info-value">
            <template v-if="checkin.aasmState !== 'pending'">
              <i v-for="({ state }, index) in checkin.videoData" :key="index" :class="videoStateIcon(state)" />
              <span class="text-muted font-size-12">{{ publishedVideoCount }} of {{ checkin.videoData.length }} successfully published to GBP</span>
            </template>
            <span v-else>Pending</span>
          </div>
          <span v-else class="info-value">Skipped</span>
        </div>
        <div v-if="checkin.gmbReview" class="d-flex mb-2">
          <span class="info-key">Review</span>
          <div class="d-flex align-items-center gap-2 info-value">
            <span>{{ checkin.gmbReview.reviewer.displayName }}</span>
            <div>
              <i v-for="i in 5"
                :key="`star-icon-${i}`"
                :class="`text-warning fa-star${i <= checkin.gmbReview.rating ? ' fa-solid' : ' fa-regular'}`" />
            </div>
          </div>
        </div>
        <MomentStatuses class="pt-2" :checkin="checkin" />
        <div v-if="allowActions" class="d-flex flex-wrap mt-4 gap-2">
          <button v-if="checkin.aasmState === 'pending'"
            @click="approveUrl(checkin.obfuscatedId)"
            :disabled="isRequestSent"
            type="button"
            class="btn btn-xs px-2 btn-primary rounded">
            Approve
          </button>
          <button v-if="checkin.aasmState === 'pending'"
            @click="rejectUrl(checkin.obfuscatedId)"
            :disabled="isRequestSent"
            type="button"
            class="btn btn-xs px-2 btn-danger rounded">
            Reject
          </button>
          <a :href="editUrl(checkin.obfuscatedId)"
            :class="['btn btn-xs px-2 rounded', { disabled: isRequestSent }]">
            <i class="far fa-edit" />
            <span class="ml-2">{{ editButtonTitle(checkin) }}</span>
          </a>
          <button
            @click="destroyCheckin(checkin.obfuscatedId)"
            :disabled="isRequestSent"
            type="button"
            class="btn btn-xs px-2 btn-outline-danger rounded">
            <i class="far fa-trash" />
            <span class='ml-2'>Delete</span>
          </button>
        </div>
        <div v-else class="d-flex flex-wrap mt-4 gap-2">
          <a :href="editUrl(checkin.obfuscatedId)"
            :class="['btn btn-xs px-2 rounded', { disabled: isRequestSent }]"
            target="_blank">
            <i class="far fa-edit" />
            <span class="ml-2">{{ editButtonTitle(checkin) }}</span>
          </a>
        </div>
      </div>
      <div v-if="mediaData.length" class="mv-checkin-item-images">
        <template v-if="mediaData.length <= 4">
          <template v-for="({ url, preview, type }, index) in mediaData">
            <img v-if="type === 'image'"
              :key="index"
              :src="preview"
              alt=""
              @click="openPreview(mediaData, index)">
            <video v-else :key="index" :src="url" @click="openPreview(mediaData, index)" />
          </template>
        </template>
        <template v-else>
          <template v-for="({ url, preview, type }, index) in mediaData.slice(0, 3)">
            <img v-if="type === 'image'"
              :key="index"
              :src="preview"
              alt=""
              @click="openPreview(mediaData, index)">
            <video v-else :key="index" :src="url" @click="openPreview(mediaData, index)" />
          </template>
        </template>
        <div v-if="mediaData.length > 4" class="mv-checkin-item-images-more" @click="openPreview(mediaData, 3)">
          +{{ mediaData.length - 3 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import { runSwal } from 'common/delete_with_swal'
import MomentStatuses from './moment_statuses'
import {
  BASE_URL, BASE_AJAX_URL, handleError
} from './helpers'

export default {
  components: {
    MomentStatuses
  },
  props: {
    checkin: { type: Object, required: true },
    allowActions: { type: Boolean, required: false, default: true }
  },
  data() {
    return {
      isRequestSent: false
    }
  },
  computed: {
    gbpPostStatus() {
      const { checkin } = this
      if (checkin.localPostStatus) return checkin.localPostStatus
      if (checkin.business.disconnected) return 'disconnected'
      if (checkin.postingError) return 'failed'

      return 'pending'
    },
    publishedVideoCount() {
      return this.checkin.videoData && this.checkin.videoData.filter(({ state }) => state === 'live').length
    },
    mediaData() {
      const media = [...this.checkin.images, ...this.checkin.videoUrls]
      return media.map((m) => ({
        url: typeof m === 'string' ? m : m.url,
        preview: m.preview,
        type: typeof m === 'string' ? 'video' : 'image'
      }))
    },
    isScheduledInThePast() {
      if (!this.checkin.scheduledAt) return false
      const scheduledAt = new Date(this.checkin.scheduledAt).getTime()
      const now = new Date().getTime()
      return scheduledAt < now
    },
    masterDate() {
      if (this.checkin.aasmState === 'pending') return null

      if (!this.checkin.scheduledAt && this.checkin.aasmState === 'approved') {
        return `<span class="text-success">Approved</span> ${this.checkin.approvedAt}`
      }

      if (this.checkin.scheduledAt && this.checkin.aasmState === 'approved' && this.isScheduledInThePast) {
        const scheduledAt = new Date(this.checkin.scheduledAt).getTime()
        const approvedAt = new Date(this.checkin.approvedAt).getTime()
        const laterDate = scheduledAt > approvedAt ? this.checkin.scheduledAt : this.checkin.approvedAt
        return `<span class="text-success">Approved</span> ${laterDate}`
      }

      if (this.checkin.scheduledAt && this.checkin.aasmState === 'approved') {
        return `<span class="text-success">Scheduled for</span> ${this.checkin.scheduledAt}`
      }

      return null
    }
  },
  methods: {
    stateClass(aasmState) {
      if (aasmState === 'approved') return 'color-success'
      if (aasmState === 'rejected') return 'color-danger '
      if (aasmState === 'pending') return 'color-warning '

      return ''
    },
    editUrl(id) {
      return `${BASE_URL}${id}/edit`
    },
    editButtonTitle(checkin) {
      const isPending = checkin.aasmState === 'pending'
      const isApproved = checkin.aasmState === 'approved'
      if (isPending) return 'Edit'
      if (isApproved && checkin.scheduledAt && !this.isScheduledInThePast) {
        return 'Edit'
      }

      return 'Show'
    },
    approveUrl(id) {
      const approve = () => {
        this.isRequestSent = true
        axios.post(`${BASE_AJAX_URL}${id}/approve`).then(() => {
          toastr.success('Moment approved successfully')
          this.$emit('updateMomentList')
        }).catch((err) => {
          handleError(err)
        }).finally(() => {
          this.isRequestSent = false
        })
      }

      if (!this.isScheduledInThePast) {
        approve()
        return
      }

      runSwal(() => {
        approve()
      }, {
        title: 'This moment is scheduled in the past',
        content: 'It will be published immediately after approval.',
        buttons: ['Cancel', 'Approve and publish'],
        dangerMode: false,
        className: 'mv-checkin-swal'
      })
    },
    rejectUrl(id) {
      this.isRequestSent = true
      axios.post(`${BASE_AJAX_URL}${id}/reject`).then(() => {
        toastr.success('Moment rejected successfully')
        this.$emit('updateMomentList')
      }).catch((err) => {
        handleError(err)
      }).finally(() => {
        this.isRequestSent = false
      })
    },
    destroyCheckin(id) {
      runSwal(() => {
        this.isRequestSent = true
        axios.delete(`${BASE_AJAX_URL}${id}`).then((res) => {
          toastr.success(res.data.message)
          this.$emit('updateMomentList')
        }).catch(() => {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        }).finally(() => {
          this.isRequestSent = false
        })
      })
    },
    openPreview(media, index) {
      this.$emit('openPreview', {
        mediaSet: media,
        mediaIndex: index
      })
    },
    videoStateIcon(state) {
      if (state === 'live') {
        return 'far fa-check-circle font-size-16 color-green'
      }

      return 'far fa-times-circle font-size-16 text-grey'
    },
    stateIcon() {
      const { checkin } = this

      const scheduledIcons = {
        approved: 'checkin_scheduled_approved.svg',
        pending: 'checkin_scheduled_pending.svg'
      }

      const defaultIcons = {
        approved: 'checkin_approved.svg',
        pending: 'checkin_pending.svg',
        rejected: 'checkin_rejected.svg'
      }

      if (checkin.scheduledAt && this.isScheduledInThePast && checkin.aasmState === 'approved') {
        return defaultIcons.approved
      }
      if (checkin.aasmState === 'rejected') return defaultIcons.rejected

      let iconsSet = defaultIcons

      if (checkin.scheduledAt) {
        iconsSet = scheduledIcons
      }

      return iconsSet[checkin.aasmState]
    }
  }
}
</script>
