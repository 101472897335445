<template>
  <div class="mv-filter">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <span class="mv-filter-title">Filtering</span>
      <button type="button" class="btn btn-sm btn-link" @click="reset">Reset</button>
    </div>

    <div class="form-field mb-4">
      <label class="m-0">Businesses</label>
      <Multiselect
        v-model="businesses"
        :options="businessesList"
        :max-height="540"
        :limit="300"
        :options-limit="300"
        :multiple="true"
        track-by="id"
        label="name"
        @select="resetBusinessRelatedFilters">
        <template slot="selection" slot-scope="{ values, isOpen }">
          <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} options selected</span>
          <span class="multiselect__single" v-else-if="values.length === 1 && !isOpen">{{ values[0].name }}</span>
        </template>
        <template slot="option" slot-scope="props">
          <div>
            <span class="d-block text-truncate">{{ props.option.name }}</span>
            <span class="d-block font-size-14 text-truncate">{{ props.option.locationData.address }}</span>
            <span class="d-block font-size-14 text-truncate">{{ props.option.locationData.storeCode }}</span>
          </div>
        </template>
        <template slot="tag">{{ '' }}</template>
      </Multiselect>
      <div class="pt-2 d-flex flex-wrap gap-2">
        <div v-for="(selectedBusiness, index) in businesses" class="tag px-2 bg-gray-lighter rounded d-flex gap-2 align-items-center" :key="index">
          <span>{{ selectedBusiness.name }}</span>
          <i class="cursor-pointer far fa-xmark" @click="businesses.splice(index, 1)" />
        </div>
      </div>
    </div>

    <div class="form-field mb-4">
      <label class="m-0">Workers</label>
      <Multiselect
        v-model="employees"
        :options="employeesListFiltered"
        :max-height="540"
        :limit="300"
        :options-limit="300"
        :multiple="true"
        track-by="id"
        label="fullName">
        <template slot="selection" slot-scope="{ values, isOpen }">
          <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} options selected</span>
          <span class="multiselect__single" v-else-if="values.length === 1 && !isOpen">{{ values[0].fullName }}</span>
        </template>
        <template slot="tag">{{ '' }}</template>
      </Multiselect>
      <div class="pt-2 d-flex flex-wrap gap-2">
        <div v-for="(selectedEmployee, index) in employees" class="tag px-2 bg-gray-lighter rounded d-flex gap-2 align-items-center" :key="index">
          <span>{{ selectedEmployee.fullName }}</span>
          <i class="cursor-pointer far fa-xmark" @click="employees.splice(index, 1)" />
        </div>
      </div>
    </div>

    <div class="form-field mb-4">
      <label class="m-0">Categories</label>
      <Multiselect
        v-model="categories"
        :options="categoriesListFiltered"
        :max-height="540"
        :limit="300"
        :options-limit="300"
        :multiple="true"
        track-by="title"
        label="title">
        <template slot="selection" slot-scope="{ values, isOpen }">
          <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} options selected</span>
          <span class="multiselect__single" v-else-if="values.length === 1 && !isOpen">{{ values[0].title }}</span>
        </template>
        <template slot="tag">{{ '' }}</template>
      </Multiselect>
      <div class="pt-2 d-flex flex-wrap gap-2">
        <div v-for="(selectedCategory, index) in categories" class="tag px-2 bg-gray-lighter rounded d-flex gap-2 align-items-center" :key="index">
          <span>{{ selectedCategory.title }}</span>
          <i class="cursor-pointer far fa-xmark" @click="categories.splice(index, 1)" />
        </div>
      </div>
    </div>

    <div class="form-field mb-4">
      <label class="m-0">By Created Date</label>
      <DatePicker
        class="w-100"
        mode="range"
        max-date="today"
        v-model="byPeriod" />
    </div>

    <div class="form-field mb-4">
      <label class="m-0">By Publishing Date</label>
      <DatePicker
        class="w-100"
        mode="range"
        v-model="byPublishingDate" />
    </div>

    <div class="mb-2">
      <p class="font-weight-bold mb-2">Review presence</p>
      <div class="d-flex flex-column align-items-start gap-2">
        <AppRadio v-for="({label, value}) in reviewPresenceOptions"
          v-model="withReview"
          name="review_presence"
          :key="value"
          :item="value"
          :id-name="`review_presence_${value}`">
          {{ label }}
        </AppRadio>
      </div>
    </div>

    <div class="mb-2">
      <p class="font-weight-bold mb-2">Schedule status</p>
      <div class="d-flex flex-column align-items-start gap-2">
        <AppCheckbox v-model="scheduled" :key="scheduled" @input="onChange">
          <span class="text-capitalize">Awaiting</span>
        </AppCheckbox>
      </div>
    </div>

    <div>
      <p class="font-weight-bold mb-2">Moderation status</p>
      <div class="d-flex flex-column align-items-start gap-2">
        <AppCheckbox v-for="(_val, key) in statuses" v-model="statuses[key]" :key="key" @input="onChange">
          <span class="text-capitalize">{{ key }}</span>
        </AppCheckbox>
      </div>
    </div>
  </div>
</template>

<script>
import qs from 'qs'
import { removeQueryParametersFromUrl } from 'common/search_params'
import Multiselect from 'vue-multiselect'
import AppCheckbox from 'vue_widgets/components/checkbox'
import DatePicker from 'vue_widgets/components/datepicker'
import AppRadio from 'vue_widgets/components/radio'

export default {
  components: {
    Multiselect, AppCheckbox, AppRadio, DatePicker
  },
  props: {
    businessesList: { type: Array, default: () => []},
    employeesList: { type: Array, default: () => []},
    categoriesList: { type: Array, default: () => []}
  },
  data() {
    return {
      businesses: [],
      employees: [],
      categories: [],
      byPeriod: [],
      byPublishingDate: [],
      withReview: '',
      scheduled: false,
      statuses: {
        approved: false,
        pending: false,
        rejected: false
      }
    }
  },
  created() {
    this.reviewPresenceOptions = [
      {
        label: 'All',
        value: ''
      },
      {
        label: 'Present',
        value: 'true'
      },
      {
        label: 'Missing',
        value: 'false'
      }
    ]
  },
  mounted() {
    this.handlePresetFilters()
  },
  computed: {
    employeesListFiltered() {
      if (!this.businesses.length) return this.employeesList
      return this.employeesList.filter((employee) => {
        let included = false
        for (let i = 0; i < this.businesses.length; i++) {
          if (employee.businessIds.includes(this.businesses[i].id)) {
            included = true
            break
          }
        }

        return included
      })
    },
    categoriesListFiltered() {
      const allCategories = this.categoriesList
      const savedCategories = {}
      const businessIds = new Set(this.appliedFilters.business)

      allCategories.forEach(({ title, businessId }) => {
        if (businessIds.size > 0 && !businessIds.has(businessId)) return

        if (!savedCategories[title]) {
          savedCategories[title] = {
            value: title,
            title,
            businessIds: [businessId]
          }

          return
        }

        savedCategories[title].businessIds.push(businessId)
      })

      return Object.keys(savedCategories).map((categoryTitle) => savedCategories[categoryTitle])
    },
    appliedFilters() {
      const filterData = {
        business: this.businesses.map((b) => b.id),
        employee: this.employees.map((e) => e.id),
        categories: this.categories.map((b) => b.title),
        statuses: Object.keys(this.statuses).filter((key) => this.statuses[key]),
        byPeriod: this.byPeriod,
        byPublishingDate: this.byPublishingDate
      }

      if (this.scheduled) {
        filterData.scheduled = true
      }

      if (this.withReview) {
        filterData.withReview = this.withReview
      }

      return filterData
    },
    filtersAreEmpty() {
      return this.appliedFilters.business.length === 0 &&
        this.appliedFilters.employee.length === 0 &&
        this.appliedFilters.categories.length === 0 &&
        this.appliedFilters.statuses.filter((status) => status).length === 0 &&
        !this.appliedFilters.scheduled &&
        typeof this.appliedFilters.withReview !== 'boolean'
    }
  },
  methods: {
    reset() {
      this.businesses = []
      this.employees = []
      this.categories = []
      this.byPeriod = []
      this.byPublishingDate = []
      this.scheduled = false
      this.statuses = {
        approved: false,
        pending: false,
        rejected: false
      }
      this.onChange()
    },
    onChange() {
      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        this.$emit('onChange', this.appliedFilters)
      }, 200)
    },
    handlePresetFilters() {
      const search = qs.parse(window.location.search, { ignoreQueryPrefix: true })
      const {
        business: businessId,
        employee: employeeId,
        status,
        categories,
        by_period: byPeriod,
        by_publishing_date: byPublishingDate
      } = search

      if (businessId) {
        const relatedBusiness = this.businessesList.find((business) => business.id === businessId)
        if (relatedBusiness) {
          this.businesses.push(relatedBusiness)
        }
      }

      if (employeeId) {
        const relatedEmployee = this.employeesList.find((employee) => employee.id === employeeId)
        if (relatedEmployee) {
          this.employees.push(relatedEmployee)
        }
      }

      if (status) {
        this.statuses[status] = true
      }

      if (categories) {
        categories.forEach((category) => {
          const relatedCategoryObject = this.categoriesList.find((categoryObject) => categoryObject.title === category.toUpperCase())
          if (!relatedCategoryObject) return
          this.categories.push(relatedCategoryObject)
        })
      }

      if (byPeriod) {
        this.byPeriod = byPeriod
      }

      if (byPublishingDate) {
        this.byPublishingDate = byPublishingDate
      }

      if (this.filtersAreEmpty) {
        this.$emit('getCheckins')
      }

      removeQueryParametersFromUrl('business', 'employee', 'status', 'categories[]', 'by_period[]')
    },
    resetBusinessRelatedFilters() {
      this.employees = []
      this.categories = []
    }
  },
  watch: {
    appliedFilters: {
      handler() {
        this.onChange()
      },
      deep: true
    }
  }
}
</script>
