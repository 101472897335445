<template>
  <div class="moment-images-control">
    <div class="moment-images-control__heading">
      <span class="h5 m-0 color-dark font-weight-semi-bold">Image</span>
      <span v-if="images.length" class="font-size-12 text-mute">{{ counterText }}</span>
      <div v-if="images.length && images.length < MAX_FILES" :class="['btn btn-xs btn-primary gap-2 position-relative', { disabled }]">
        <input type="file"
          ref="file"
          class="moment-images-control__add-btn"
          multiple
          :accept="acceptTypes"
          :disabled="disabled"
          @change="onFileChange">
        <i class="fa-regular fa-image" />
        Add photo
      </div>
    </div>
    <div class="moment-images-control__list">
      <div v-if="!images.length" class="d-flex flex-column align-items-center text-center">
        <p>
          Select a file from your device.<br>
          You can upload 10 more images.
        </p>
        <div :class="['btn btn-xs btn-primary gap-2 position-relative', { disabled }]">
          <input type="file"
            ref="file"
            class="moment-images-control__add-btn"
            multiple
            :accept="acceptTypes"
            :disabled="disabled"
            @change="onFileChange">
          <i class="fa-regular fa-image" />
          Add photo
        </div>
      </div>
      <span class="d-block mb-1 font-size-14 font-weight-semi-bold text-primary">Cover image</span>
      <draggable
        v-if="images.length"
        v-model="images"
        handle=".fa-grip-dots-vertical"
        :disabled="disabled"
        @change="$emit('setImages', images)">
        <ImagesItem
          v-for="(image, index) in images"
          :key="image.url"
          :image="image"
          :disabled="disabled"
          :ai-messages="aiMessages"
          @updateImage="(fields) => updateImage(fields, index)"
          @removeImage=removeImage(index) />
      </draggable>
      <div v-else class="moment-images-control__item first-child p-4">
        <span class="text-mute font-size-12">The first image uploaded will be used as the cover image.</span>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { subscribe, removeChannel } from 'vue_widgets/audit_report_configurations/helpers'
import ImagesItem from './images_item.vue'

const MAX_FILES = 10

export default {
  components: { draggable, ImagesItem },
  props: {
    checkin: { type: Object, required: true },
    acceptTypes: { type: String, default: 'image/png, image/jpg, image/jpeg' },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      images: this.checkin.images,
      aiMessages: []
    }
  },
  created() {
    this.MAX_FILES = MAX_FILES

    this.channel = subscribe('DescribeImageChannel', (msg) => {
      this.aiMessages.push(msg)
    })
  },
  beforeDestroy() {
    removeChannel(this.channel)
  },
  computed: {
    counterText() {
      const count = this.MAX_FILES - this.images.length
      return `You can upload ${count} more image${count !== 1 ? 's' : ''}.`
    }
  },
  methods: {
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files

      if (!files.length) {
        return
      }

      const wrongFormatFiles = []
      const filteredFiles = Array.from(files).filter(({ name, type }) => {
        if (this.acceptTypes.includes(type)) return true

        wrongFormatFiles.push(name)
        return false
      }).slice(0, MAX_FILES - this.images.length)

      if (wrongFormatFiles.length > 0) {
        toastr.warning(`Can't upload files:<br>${wrongFormatFiles.join('<br>')}`, { timeOut: 2000 })
      }

      this.$refs.file.value = ''
      if (!/safari/i.test(navigator.userAgent)) {
        this.$refs.file.type = ''
        this.$refs.file.type = 'file'
      }

      this.createImage(filteredFiles)
    },
    createImage(files) {
      const vm = this
      for (let index = 0; index < files.length; index++) {
        const reader = new FileReader()
        reader.onload = (event) => {
          const imageUrl = event.target.result
          vm.images.push({ url: imageUrl })
          this.$emit('setImages', this.images)
        }
        reader.readAsDataURL(files[index])
      }
    },
    removeImage(index) {
      this.images.splice(index, 1)
      this.$emit('setImages', this.images)
    },
    updateImage(imageFields, index) {
      this.images[index] = {
        ...this.images[index],
        ...imageFields
      }
      this.$emit('setImages', this.images)
    }
  }
}
</script>
