import axios from 'axios'
import turbolinksReload from '../../common/turbolinks_reload'

export default ({ mobileBusinessPath }) => {
  const renewSubscriptionBtn = document.getElementById('js-business-renew-subscription')

  renewSubscriptionBtn?.addEventListener('click', (e) => {
    e.preventDefault()

    axios.patch(`${mobileBusinessPath}/resubscribe`)
      .then(() => {
        turbolinksReload(() => {
          toastr.success('Subscribed successfully')
        })
      })
  })
}
